//testnet
// export const chainId = {
//     eth: 5,
//     zk: 280,
//     arb: 421613,
//     opt: 10,
//     nova: 42170,
//     base: 8453,
//     linea: 59144,
//     zkPol:1101
// }
//mainnet
export const chainId = {
    eth: 1,
    zk: 324,
    arb: 42161,
    opt: 10,
    nova: 42170,
    base: 8453,
    linea: 59144,
    zkPol:1101
}
