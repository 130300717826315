import { createSlice } from '@reduxjs/toolkit'
import logo_zk from '../../img/logo_zk.svg'
import logo_eth from '../../img/logoi_ethereum.svg'
import {chainId} from "../../chainIds/chainids";

const initialState = {
    choosedChainFrom: "",
    imgFrom: logo_zk,
    remoteChainIdFrom: 0,
    chainIdFrom: 0,
    choosedChainTo: "",
    imgTo:  logo_eth,
    remoteChainIdTo: 0,
    chainIdTo: 0,
}

export const choosedChainSliceReducer = createSlice({
    name: 'choosedChain',
    initialState,
    reducers: {
        dispatchChoosedChainValuesFrom: (state, action) => {
            const {choosedChainFrom, imgFrom, remoteChainIdFrom,chainIdFrom} = action.payload
            state.choosedChainFrom = choosedChainFrom;
            state.imgFrom = imgFrom;
            state.remoteChainIdFrom = remoteChainIdFrom;
            state.chainIdFrom = chainIdFrom
        },
        dispatchChoosedChainValuesTo(state, action){
            const {choosedChainTo, imgTo, remoteChainIdTo, chainIdTo} = action.payload
            state.choosedChainTo = choosedChainTo;
            state.imgTo = imgTo;
            state.remoteChainIdTo = remoteChainIdTo;
            state.chainIdTo = chainIdTo;
        },
    },
})

export const { dispatchChoosedChainValuesFrom
    , dispatchChoosedChainValuesTo,

} = choosedChainSliceReducer.actions

export default choosedChainSliceReducer.reducer