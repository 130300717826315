import React from 'react';
import './ContentInProgress.css'
import logo_eth from '../../../../img/ethereum_status_logo.svg'
import logo_zk from '../../../../img/logo_zk.svg'
import {useAppSelector} from "../../../../store/hooks";
import {chainId} from "../../../../chainIds/chainids";


const ContentInProgress = () => {
    const {imgFrom, imgTo, choosedChainFrom, choosedChainTo,chainIdFrom, chainIdTo} = useAppSelector(state => state.choosedChain);
    const {scanUrl, lzUrl} = useAppSelector(state => state.chainValues)
    return (
        <>
            <div className={"wrap_title"}>
                <span className={"title"}>Transferring...</span>
            </div>
            <div className={"wrap_modal_status"}>
                <div className={"wrap_title_status"}>
                    <span>your transfer is accepted</span>
                </div>
                    <div className={"wrap_chains_status"}>
                        <div className={"wrap_chain_status"}>
                            <div className={"chain_status"}>
                                <img src={chainIdFrom === chainId.eth ? logo_eth : imgFrom}/>
                            </div>
                            <div className={"choosed_chains"}>
                                <img src={imgFrom}/>
                                <span>{choosedChainFrom}</span>
                            </div>
                        </div>
                        <div className={"wrap_chain_status"}>
                            <div className={"chain_status"}>
                                <img src={chainIdTo === chainId.eth ? logo_eth : imgTo}/>
                            </div>
                            <div className={"choosed_chains"}>
                                <img src={imgTo}/>
                                <span>{choosedChainTo}</span>
                            </div>
                        </div>
                        {scanUrl && <a className={"ref_hash"} href={scanUrl} target={"_blank"}>View on Explorer</a>}
                        {lzUrl && <a className={"ref_hash_next"} href={lzUrl} target={"_blank"}>Check on LayerZero</a>}
                    </div>
            </div>
        </>


    );
};

export default ContentInProgress;
