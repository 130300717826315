import React from 'react';
import './ContentFailed.css'
import {useAppSelector} from "../../../../store/hooks";
import failed from '../../../../img/failed.svg'

const ContentFailed = () => {
    const state = useAppSelector(state => state.nftValues);
    return (
        <>
            <div className={"wrap_title"}>
                <span className={"title"}>OOPS</span>
            </div>
            <div className={"wrap_modal_status_done"}>

                <div className={"wrap_title_status_done"}>
                    <span className="title_status">something was wrong</span>
                    <span className={"text_under_title_status"}>Please, try transfer again</span>
                </div>
                <div className={"wrap_chains_status_done"}>
                    <div className={"wrap_nft_content"}>
                        <div className={"nft_content"}>
                            <img className={"img_nft"} src={state.image}/>
                            <div className={"wrap_status_values"}>
                                <span>Zk Pengz</span>
                                <span>#{state.tokenId}</span>
                            </div>
                        </div>
                        <img src={failed}/>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentFailed;