import { createSlice } from '@reduxjs/toolkit'
import {chainId} from "../../chainIds/chainids";


const initialState = {
    chainValue: chainId.zk,
    scanUrl: '',
    lzUrl: '',
    allChains: [],
    hash: ''
}

export const chainSliceReducer = createSlice({
    name: 'chainValue',
    initialState,
    reducers: {
        dispatchAllChains: (state, action) => {
            const {allChains} = action.payload;
            state.allChains = allChains;
        },
        dispatchChainValues: (state, action) => {
            const {chainValue} = action.payload
            state.chainValue = chainValue;
        },
        dispatchScanUrl: (state, action) => {
            const {scanUrl} = action.payload
            state.scanUrl = scanUrl
        },
        dispatchLzUrl: (state, action) => {
            const {lzUrl} = action.payload
            state.lzUrl = lzUrl
        },
        dispatchHash: (state, action) => {
            const {hash} = action.payload
            state.hash = hash
        }
    },
})

export const {
    dispatchChainValues,
    dispatchScanUrl,
    dispatchAllChains,
    dispatchHash,
    dispatchLzUrl
} = chainSliceReducer.actions

export default chainSliceReducer.reducer
