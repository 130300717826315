import {ethers} from "ethers";
import Web3 from 'web3'
import {getEnv} from "../utils";
import {createClient} from "@layerzerolabs/scan-client";
import {useNetwork, useSwitchNetwork} from "wagmi";
import axios from "axios";
const serverUrl = getEnv(process.env.REACT_APP_SERVER_URL, 'REACT_APP_SERVER_URL');

export const listenReceiveEvent = async (chainId: number, hash: string) => {
    const sleep = (ms = 1e3) => new Promise((resolve) => setTimeout(resolve, ms));
    const env = chainId < 1e4 ? "mainnet" : chainId < 2e4 ? "testnet" : "sandbox";
    const client = createClient(env);
    const apiL0 = getEnv(process.env.REACT_APP_API_L0, 'REACT_APP_API_L0')
    while (true) {
        try {
            const data = await axios.get(`${apiL0}tx/${hash}`)
            const res = await (new ethers.providers.Web3Provider(Web3.givenProvider)).getTransactionReceipt(hash)
            if (res && res.status == 0) {
                return "FAILED"
            }
            const messages = data.data.messages
            const message = messages[0];
            if (messages.length > 1) {
                return Promise.reject(new Error(`More than one message`));
            } else if ((message == null ? void 0 : message.status) === "FAILED" ) {
                return Promise.reject(new Error(`Message failed ${message.dstTxError}`));
            } else if ((message == null ? void 0 : message.status) === "DELIVERED" ) {
                if (message.dstTxHash){
                    return message;
                }
            }else if ((message == null ? void 0 : message.status) === "BLOCKED" ) {
                if (message.dstTxHash){
                    return message;
                }
            }else if ((message == null ? void 0 : message.status) === "PAYLOAD_STORED" ) {
                if (message.dstTxHash){
                    return message;
                }
            }


        } catch (e) {

        }
        await sleep(3e3);
    }
}

export const isApproved = async (userAddress: string): Promise<{
    isApproved: boolean
}> => await fetch(`${serverUrl}/approved?userAddress=${userAddress}`).then(res => res.json())
export const approve = async () => {
    const signer = new ethers.providers.Web3Provider(Web3.givenProvider).getSigner()
    const data = await fetch(`${serverUrl}/approve`).then(res => res.json())
    await signer.sendTransaction(data)
}
export const sendToken = async (toChain: number, fromChain: number, userAddress: string, tokenId: string | number) => {
    const signer = new ethers.providers.Web3Provider(Web3.givenProvider).getSigner()
    const data = await fetch(`${serverUrl}/bridge?toChainId=${toChain}&userAddress=${userAddress}&tokenId=${tokenId}&fromChainId=${fromChain}`).then(res => res.json())
    const transaction = await signer.sendTransaction(data)
    return transaction.hash
}

export const retryTransaction = async (hash?: string, chainId? : number) => {
    const signer = new ethers.providers.Web3Provider(Web3.givenProvider).getSigner()
    let uri = hash !== "" ? `${serverUrl}/retryTransaction?transactionHash=${hash}` : `${serverUrl}/retryTransaction?chain=${chainId}`
    const data = await fetch(uri).then(res => res.json())
    for(let tx of data) {
        try {
            const transaction = await signer.sendTransaction(tx.transaction)
            return transaction
        }catch {}
    }
    throw "all good"
}