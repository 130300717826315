import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import './ModalRetry.css'
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import DropDown from "../../../../../components/dropDown/DropDown";
import logo_eth from "../../../../../img/logoi_ethereum.svg";
import logo_zk from "../../../../../img/logo_zk.svg";
import logo_arb from "../../../../../img/AF_logomark.svg";
import {chainId} from "../../../../../chainIds/chainids";
import {retryTransaction} from "../../../../../services/contract";
import {useNetwork, useSwitchNetwork} from "wagmi";
import {dispatchVisibleModal} from "../../../../../store/slices/modalSlice";
import arrow from '../../../../../img/arrow_drop_down.svg'
import allRoutes from "../../../../../config/router/routes/allRoutes.json";
import {chainNames} from "../../../../../chainIds/chainNames";
import logo_base from "../../../../../img/base-logo.svg";
import logo_optimism from "../../../../../img/optimism_logo.svg";
import logo_nova from "../../../../../img/nova_arb_logo.svg";
import logo_linea from "../../../../../img/linea_logo.svg";
import logo_polygon from "../../../../../img/polygon_logo.svg";

interface IChain {
    name: string,
    chainId: number,
    remoteChainId: number
}

const ModalRetry = () => {
    const {allChains} = useAppSelector(state => state.chainValues)
    const str = "It seems to be there are no issues with your NFT, please try to check your wallet or invenotry again. In case of any further troubles, ping us in"
    const [visible, setVisible] = useState<boolean>(false)
    const [textTitle, setTextTitle] = useState("Lost your NFT? Choose Network where you’ve sent it and press Retry")
    const [textButton, setTextButton] = useState<string>("retry")
    const dispatch = useAppDispatch()
    const [chainDropDawn, setChainDropDawn] = useState({
        name: "Ethereum",
        chainId: chainId.eth,
        logo: logo_eth
    })
    const { chain } = useNetwork();
    const { switchNetworkAsync } = useSwitchNetwork();
    const imgChoosed = (item: IChain) => {
        switch (item.name) {
            case chainNames.eth : {
                return logo_eth;
            }
            case chainNames.zk : {
                return logo_zk;
            }
            case chainNames.arb : {
                return logo_arb
            }
            case chainNames.base: {
                return logo_base
            }
            case chainNames.opt: {
                return logo_optimism
            }

            case chainNames.arbNova: {
                return logo_nova
            }
            case chainNames.linea: {
                return logo_linea
            }
            case chainNames.zkPol: {
                return logo_polygon
            }
        }
    }
    const retry = async () => {
        setTextButton("Pending…")
        try{
            if(chain?.id !== chainDropDawn.chainId) await switchNetworkAsync?.(chainDropDawn.chainId)
            try {
                const transaction = await retryTransaction("", chainDropDawn.chainId);
                const res = await transaction.wait();
                if (res && res.status == 1) {
                    setTextTitle("Success! It may take a couple of minutes for your NFT to appear in your wallet")
                    setTextButton("success")
                } else if(res &&  res.status === 0){
                    throw new Error("failed")
                }
            } catch (e){
                setTextTitle("It seems to be there are no issues with your NFT, please try to check your wallet or invenotry again. In case of any further troubles, ping us in")
                setTextButton("try again")
            }
        }catch (e){
            setTextTitle("Lost your NFT? Choose Network where you’ve sent it and press Retry")
            setTextButton("retry")
        }
    }

    const checkTextButton = async () => {
        switch (textButton){
            case "Pending":{
                break
            }
            case "success": {
                break
            }
            case "try again": {
                await retry()
                break
            }
            case "retry": {
                await retry()
                break
            }
        }
    }

    return (
        <div className={"wrap"}>
            <div className={"wrap_modal_retry"}>
                <span onClick={() => dispatch(dispatchVisibleModal({visibleModal: false}))} className={"close_button_retry"}>X</span>
                <span className={"text_title_retry"}>{textTitle} {textTitle === str && <a href={'https://discord.gg/DdqX4G2c4U'} target={"_blank"}>Discord</a>}</span>
                <div onClick={() => setVisible(!visible)} className={"wrap_dropDawn_retry"}>
                    <img src={chainDropDawn.logo}/>
                    <span>{chainDropDawn.name}</span>
                    <img src={arrow}/>
                    {visible &&
                        <div className={"wrap_all_chains"}>
                            {allChains && allChains.map((item: IChain, index) => (
                                <div key={index} onClick={() => setChainDropDawn({name: item.name, chainId: item.chainId, logo: imgChoosed(item)})} className={"wrap_chains"}>
                                    <img src={imgChoosed(item)}/>
                                    <span className={"chains_span"}>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div onClick={()=> checkTextButton()} className={"button_retry"}>
                    <span>{textButton}</span>
                </div>
            </div>
        </div>

    );
};

export default ModalRetry;