import allRoutes from "./allRoutes.json";
import Bridge from "../../../pages/Bridge/BridgeDesktop/Bridge";
import History from "../../../pages/History/History";
import StatusTransaction from "../../../pages/StatusTransaction/StatusTransaction";
import TransferInfo from "../../../pages/TransferInfo/TransferInfo";
import Media from "react-media";
import BridgeMobile from "../../../pages/Bridge/BridgeMobile/BridgeMobile";
import Inventory from "../../../pages/Inventory/Inventory";
import BridgeScreen from "../../../pages/Bridge/BridgeScreen";
import RetrySuccess from "../../../pages/StatusTransaction/retrySuccess/RetrySuccess";


const routesMap = {
  [allRoutes.Bridge]: <BridgeScreen/>,
  [allRoutes.History]: <History />,
  [allRoutes.Status]: <StatusTransaction />,
  [allRoutes.TransferInfo]: <TransferInfo/>,
  [allRoutes.Inventory]: <Inventory/>,
  [allRoutes.Success]: <RetrySuccess/>,

};
export default routesMap;
