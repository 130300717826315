import {useEffect, useState} from "react";
import {listenReceiveEvent,} from "../services/contract";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {chainId} from "../chainIds/chainids";
import {getEnv} from "../utils";
import {dispatchScanUrl, dispatchLzUrl} from "../store/slices/chainSlice";

export type StatusType = "INFLIGHT" | "DELIVERED" | "BLOCKED" | "FAILED" | "PAYLOAD_STORED"
const useStatus = () => {
    const [status, setStatus] = useState<StatusType>("INFLIGHT")
    const {remoteChainIdFrom, chainIdTo} = useAppSelector(state => state.choosedChain)
    const {hash} = useAppSelector(state => state.chainValues)
    const dispatch = useAppDispatch()
    const scanUrlEth = getEnv(process.env.REACT_APP_SCAN_URL_ETH, "REACT_APP_SCAN_URL_ETH")
    const scanUrlZk = getEnv(process.env.REACT_APP_SCAN_URL_ZK, "REACT_APP_SCAN_URL_ZK")
    const scanUrlArb = getEnv(process.env.REACT_APP_SCAN_URL_ARB, "REACT_APP_SCAN_URL_ARB")
    const scanUrlOpt = getEnv(process.env.REACT_APP_SCAN_URL_OPT, 'REACT_APP_SCAN_URL_OPT');
    const scanUrlBase = getEnv(process.env.REACT_APP_SCAN_URL_BASE, 'REACT_APP_SCAN_URL_BASE')
    const scanUrlNoVa = getEnv(process.env.REACT_APP_SCAN_URL_NOVA, 'REACT_APP_SCAN_URL_NOVA');
    const scanUrlLinea = getEnv(process.env.REACT_APP_SCAN_URL_LINEA, 'REACT_APP_SCAN_URL_LINEA');
    const scanUrlZkPolygon = getEnv(process.env.REACT_APP_SCAN_URL_ZKPOLYGON, 'REACT_APP_SCAN_URL_ZKPOLYGON')
    const scanUrlLz = getEnv(process.env.REACT_APP_SCAN_URL_LZ, 'REACT_APP_SCAN_URL_LZ')
    const scanUrl = () => {
        switch (chainIdTo) {
            case chainId.eth : {
                return scanUrlEth
            }
            case chainId.zk:{
                return scanUrlZk
            }
            case chainId.arb: {
                return scanUrlArb
            }
            case chainId.linea: {
                return scanUrlLinea
            }
            case chainId.opt: {
                return scanUrlOpt
            }
            case chainId.nova: {
                return scanUrlNoVa
            }
            case chainId.base: {
                return scanUrlBase
            }
            case chainId.zkPol: {
                return scanUrlZkPolygon
            }
        }
    }
    const getStatus  = async () => {
        try{
            const data = await listenReceiveEvent(remoteChainIdFrom, hash);
            dispatch(
                dispatchScanUrl({
                    scanUrl: `${scanUrl()}tx/${data.dstTxHash}`
                })
            );
            dispatch(
                dispatchLzUrl({
                    lzUrl: `${scanUrlLz}tx/${data.dstTxHash}`
                })
            );
            setStatus(data.status)
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(()=> {
        if(hash){
            getStatus()
        }

    }, [hash, remoteChainIdFrom])

    return status;
}


export default useStatus;
