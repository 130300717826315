import React from 'react';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ModalInventory from "./modalInventory/ModalInventory";
import Media from "react-media";
import ChooseNft from "./modalInventoryMobile/ChooseNft";
import {useAccount} from "wagmi";
import {Navigate} from "react-router-dom";

const Inventory = () => {
    const {address} = useAccount();
    if(!address) return <Navigate to={'/'} replace />;

    return (
        <div className={"wrap_main_page"}>
            <Header/>
            <div className={"wrap_content"}>
                <Media query={{ maxWidth: 600 }}>
                    {(matches) => (matches ? <ChooseNft /> : <ModalInventory />)}
                </Media>
            </div>

            <Footer/>
        </div>
    );
};

export default Inventory;