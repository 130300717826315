import React from "react";
import Header from "../../../../components/header/Header";
import NftCard from "../../../Inventory/modalInventoryMobile/components/NftCard";
import Footer from "../../../../components/footer/Footer";
import styles from "./status.module.css";

const Unsuccessful = () => {
  return (
    <>
        <h1 className={styles.status_title}>SOMETHIG WAS WRONG</h1>
        <h2 className={styles.status_subtitle}>Please, try transfer again</h2>
        <div className={styles.status_main}>
          <NftCard />
        </div>
    </>
  );
};

export default Unsuccessful;
