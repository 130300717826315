import React from 'react';
import './Bridge.css'
import Header from "../../../components/header/Header";
import ModalDiscWallet from "./modalDiscWallet/ModalDiscWallet";
import ModalConnectWallet from "./modalConnectWallet/ModalConnectWallet";
import {useAccount} from "wagmi";
import Footer from "../../../components/footer/Footer";

const Bridge = () => {
    const {isConnected} = useAccount()
    return (
        <>
            <div style={{position: 'relative'}} className={"wrap_main_page"}>
                <Header/>
                <div className={"wrap_content"}>
                    {isConnected ? <ModalConnectWallet/> : <ModalDiscWallet/>}
                </div>
                <Footer/>
            </div>
        </>

    );
};

export default Bridge;