import React from 'react';
import Header from "../../components/header/Header";
import ModalTransferInfo from "./modalTransferInfo/ModalTransferInfo";
import Footer from "../../components/footer/Footer";
import {Navigate} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";

const TransferInfo = () => {
    const {tokenId} = useAppSelector(state => state.nftValues)
    if(tokenId === 0) return <Navigate to={'/'} replace />;

    return (
        <>
            <div className={"wrap_main_page"}>
                <Header/>
                <div className={"wrap_transfer_info_block"}>
                    <div className={"wrap_title"}>
                        <span className={"title"}>Transfer your nft</span>
                    </div>
                    <ModalTransferInfo/>
                </div>
                <Footer/>
            </div>
        </>


    );
};

export default TransferInfo;