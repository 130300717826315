import {createSlice} from '@reduxjs/toolkit'
import peng_img from '../../img/img_peng.png'

const initialState = {
    tokenId: 0,
    image: peng_img,
    name: '',
    chainNft: ''
}

export const nftSliceReducer = createSlice({
    name: 'nftValues',
    initialState,
    reducers: {
        dispatchNftValues: (state, action) => {
            const {name, tokenId, image, chainNft} = action.payload
            state.tokenId = tokenId;
            state.chainNft = chainNft;
            state.name = name;
            state.image = image;
        },

        dispatchResetValues: (state) => {
            state.tokenId = 0;
            state.chainNft = "";
            state.image = peng_img;
            state.name = "";
        }
    },
})

export const {
    dispatchNftValues,
    dispatchResetValues
} = nftSliceReducer.actions

export default nftSliceReducer.reducer