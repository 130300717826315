import React, {useEffect} from 'react';
import Media from "react-media";
import BridgeMobile from "./BridgeMobile/BridgeMobile";
import Bridge from "./BridgeDesktop/Bridge";
import axios from "axios";
import {getEnv} from "../../utils";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {dispatchAllChains} from "../../store/slices/chainSlice";
import {dispatchChoosedChainValuesFrom, dispatchChoosedChainValuesTo} from "../../store/slices/choosedChain";
import {chainId} from "../../chainIds/chainids";
import StatusTransaction from "../StatusTransaction/StatusTransaction";
interface IChain {
    name: string,
    chainId: number,
    remoteChainId: number
}
const BridgeScreen = () => {
    return (
        <>
            <Media query={{ maxWidth: 600 }}>
                {(matches) => (matches ? <BridgeMobile /> : <Bridge />)}
            </Media>

        </>
    );
};

export default BridgeScreen;