import React from 'react';
import './ModalInventory.css'
import Nft from "./NftComponentinventory/Nft";
import useFilterNft from "../../../hooks/useFilterNft";
import {useNavigate} from "react-router-dom";
import DropDown from "../../../components/dropDown/DropDown";
export interface INft {
    chain: string,
    image: string,
    name: string,
    tokenId: number,
}

interface IModalInventory {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    visible: boolean
}

const ModalInventory = () => {
    const filteredNfts = useFilterNft();
    const navigate = useNavigate()
    return (
            <div className={"wrap_inventory"}>
                <span>Nft</span>
                <div>
                    <DropDown textTitle={'From'}/>
                </div>
                <div onClick={() => navigate(-1)} className={"close_button_inventory"}>X</div>
                <div className={"wrap_nfts_inventory"}>
                    {filteredNfts?.length !== 0 ? filteredNfts?.map((item: INft) => (
                            <div key={item.tokenId}>
                                <Nft
                                    name={item.name}
                                    chain={item.chain}
                                    image={item.image}
                                    tokenId={item.tokenId}
                                />
                            </div>)) :
                        <div>
                            <span>No Nfts in this Chain</span>
                        </div>}
                </div>
            </div>
    );
};

export default ModalInventory;