import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MobileMenuIcon } from "../../../img/mobile_menu.svg";
import logo from "../../../img/logo.svg";

import allRoutes from "../../../config/router/routes/allRoutes.json";
import ConnectWallet from "../WalletButton";
import Media from "react-media";
import { useAccount } from "wagmi";
import {useAppDispatch} from "../../../store/hooks";
import {dispatchVisibleModal} from "../../../store/slices/modalSlice";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu = ({ isOpen, setIsOpen }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { address } = useAccount();

  const handleVisible = () => {
      setIsOpen((prev) => !prev)
      dispatch(dispatchVisibleModal({visibleModal: true}))
  }
  return (
    <div className={`mobile_menu`}>
            <img src={logo} alt="logo" />
        {!address && <div className={"wrap_beta_text_mobile"}>
            <span>Beta Test</span>
        </div>}
      <div className={"mobile_menu_wrapper"}>
        {address ? (
          <ConnectWallet />
        ) : (
          <Media query={"(min-width: 365px)"}>
            <ConnectWallet />
          </Media>
        )}
        <button
          className={"mobile_menu_button"}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <MobileMenuIcon />
        </button>
      </div>
      {isOpen && (
        <ul className={"mobile_menu_links"}>
            <span
              onClick={() => navigate(allRoutes.Bridge)}
              className={"navigate_span"}
            >
              Bridge
            </span>
              <span className={"navigate_span"} onClick={() => handleVisible()}>
                  Find NFT
              </span>
        </ul>
      )}
    </div>
  );
};

export default MobileMenu;
