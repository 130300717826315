import React, {useEffect, useState} from 'react';
import './ModalConnectWallet.css'
import arrow from "../../../../img/arrow.svg";
import NftComponent from "./nftComponents/NftComponent";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import axios from "axios";
import {getEnv} from "../../../../utils";
import {useAccount} from "wagmi";
import {dispatchNftArray} from "../../../../store/slices/nftArraySlice";
import {useNavigate} from "react-router-dom";
import allRoutes from '../../../../config/router/routes/allRoutes.json'
import DropDown from "../../../../components/dropDown/DropDown";
import {dispatchChoosedChainValuesFrom, dispatchChoosedChainValuesTo} from "../../../../store/slices/choosedChain";
import {dispatchResetValues} from "../../../../store/slices/nftReducer";
import stuck_peng from '../../../../img/stuck_peng.svg'
import cloud from '../../../../img/cloud_peng.svg'
import ModalRetry from "./modalRetry/ModalRetry";
import {INft} from "../../../Inventory/modalInventory/ModalInventory";
import {dispatchVisibleModal} from "../../../../store/slices/modalSlice";
import ContentDone from "../../../StatusTransaction/StatusDesktop/contentDone/ContentDone";
import Successful from "../../../StatusTransaction/StatusMobile/components/Successful";
const ModalConnectWallet = () => {
    const {tokenId} = useAppSelector(state => state.nftValues);
    const {choosedChainFrom, choosedChainTo, imgFrom, imgTo} = useAppSelector(state => state.choosedChain)
    const dispatch = useAppDispatch()
    const {address} = useAccount();
    const {visibleModal} = useAppSelector(state => state.modal)
    const navigate = useNavigate()
    const serverUrl = getEnv(process.env.REACT_APP_SERVER_URL, "REACT_APP_SERVER_URL");
    const state = useAppSelector(state => state.choosedChain)
    const {nftsArray} = useAppSelector(state => state.nftArray)
    const sendToTransfer = () => {
        navigate(allRoutes.TransferInfo)
    }
    const getNftsUser = async () => {
        await axios.get(`${serverUrl}/nfts?userAddress=${address}`)
            .then(res => dispatch(dispatchNftArray({nftsArray: res.data})))
    }

    useEffect(() => {
        getNftsUser()
    }, [address])

    const checkTokenId = nftsArray.filter((e: INft) => e.tokenId == tokenId).length !== 0
    if(!checkTokenId) dispatch(dispatchResetValues())
    const revertChains = () => {
        const choosedChain = state.choosedChainFrom;
        const chainId = state.chainIdFrom;
        const remoteChainId = state.remoteChainIdFrom;
        const img = state.imgFrom;
        dispatch(dispatchChoosedChainValuesFrom(
            {choosedChainFrom: state.choosedChainTo,
                chainIdFrom: state.chainIdTo,
                remoteChainIdFrom: state.remoteChainIdTo,
                imgFrom: state.imgTo
            }
        ))
        dispatch(dispatchChoosedChainValuesTo(
            {choosedChainTo: choosedChain,
                chainIdTo: chainId,
                remoteChainIdTo: remoteChainId,
                imgTo: img
            }
        ))
        dispatch(dispatchResetValues())
    }

        return (
            <div>
                <div className={"wrap_modal_div"}>
                    <div className={"wrap_title"}>
                        <span className={"title"}>Transfer your NFT</span>
                        <span className={"title_under"}>Fast, reliable, private</span>
                        <span className={"title_warning"}>Transfer is not possible between some networks</span>
                    </div>
                    <div className={"wrap_modal"}>
                        <div className={"transfer"}>
                            <DropDown textTitle={"From"}/>
                            <img onClick={() => revertChains()} className={"arrow"} src={arrow} alt={"arrow"}/>
                            <DropDown textTitle={"To"}/>
                        </div>
                        <div className={"wrap_nfts_transfer"}>
                            <NftComponent isBlocked={false} tokenId={tokenId} choosedChain={choosedChainFrom} img={imgFrom}/>
                            <NftComponent isBlocked={true} tokenId={tokenId} choosedChain={choosedChainTo} img={imgTo}/>
                        </div>
                    </div>
                    <button onClick={() =>
                        (tokenId && (state.choosedChainFrom !== state.choosedChainTo)) ? sendToTransfer() : () => {
                        }
                    }
                            className={tokenId && (state.choosedChainFrom !== state.choosedChainTo) ? "connect_wallet_active" : "connect_wallet_notActive"}>
                        <span>TRANSFER</span>
                    </button>
                </div>
                <div className={"wrap_stuck_peng"}>
                    <div className={"wrap_img"}>
                        <img className={"stuck"} src={stuck_peng}/>
                        <img onClick={() => dispatch(dispatchVisibleModal({visibleModal: true}))} className={"cloud"} src={cloud}/>
                    </div>
                </div>
                {visibleModal && <ModalRetry/>}
            </div>

        );
    };

    export default ModalConnectWallet;
