import React from 'react';
import './History.css'
import Header from "../../components/header/Header";
const History = () => {
    return (
        <div>
            <Header/>
        </div>
    );
};

export default History;