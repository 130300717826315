import React, {Suspense, useEffect, useState} from 'react';
import wallet from '../../../../../img/ri_wallet-fill.svg';
import './NftComponent.css';
import {INft} from "../../../../Inventory/modalInventory/ModalInventory";
import {useAppSelector} from "../../../../../store/hooks";
import allRoutes from '../../../../../config/router/routes/allRoutes.json'
import {useNavigate} from "react-router-dom";
import {chainNames} from "../../../../../chainIds/chainNames";
interface INftComponent{
    choosedChain: string,
    img: string,
    tokenId : number,
    isBlocked: boolean
}

const NftComponent: React.FC<INftComponent> = ({choosedChain, img, tokenId, isBlocked}) => {
    const state = useAppSelector(state => state.nftValues);
    const {nftsArray} = useAppSelector(state => state.nftArray);
    const navigate = useNavigate()
    const checkArrayLength = () => {
        switch (choosedChain) {
            case chainNames.zk : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'zk');
                return filterNfts.length
            }
            case chainNames.eth : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'eth');
                return filterNfts.length
            }
            case chainNames.arb : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'arb');
                return filterNfts.length
            }
            case chainNames.zkPol : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'zkPolygon');
                return filterNfts.length
            }
            case chainNames.opt : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'opt');
                return filterNfts.length
            }
            case chainNames.linea : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'linea');
                return filterNfts.length
            }
            case chainNames.arbNova : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'arbNova');
                return filterNfts.length
            }
            case chainNames.base : {
                const filterNfts = nftsArray?.filter((item: INft) => item.chain === 'base');
                return filterNfts.length
            }

        }
    }


    return (
        <div>
            <div onClick={isBlocked ? () => {} : () => navigate(allRoutes.Inventory)} className={"wrap_nft"}>
                <img className={"img_peng"} src={state.image} alt={"img_peng"}/>
                {tokenId ? <div className={"wrap_values_nft"}>
                    <div className={"wrap_chain_connect"}>
                        <img className={"chain_img"} src={img}/>
                        <span className={"choosed_chain"}>{choosedChain}</span>
                    </div>

                    <span>Zk Pengz</span>
                    <div>
                        <img src={wallet} alt={"wallet"}/>
                        <span>{checkArrayLength()}</span>
                    </div>
                </div> : <span className={"text_choose"}>Click to choose NFT</span>}

            </div>
        </div>

    );
};

export default NftComponent;