import React from "react";

import "./Header.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Media from "react-media";

export const ConnectWallet = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(() => {
              if (!connected) {
                return (
                  // <Media query={"(min-width: 769px)"}>
                  <span onClick={openConnectModal} className={"connect_button"}>
                    Connect wallet
                  </span>
                  // </Media>
                );
              }
              if (chain.unsupported) {
                return (
                  //<Media query={"(min-width: 769px)"}>
                  <span onClick={openChainModal} className={"connect_button"}>
                    Switch Network
                  </span>
                  // </Media>
                );
              }
              return (
                <button onClick={openAccountModal} className={"account_button"}>
                  <span className={"account_button_balance"}>
                    {account.displayBalance ? account.displayBalance : ""}
                  </span>
                  <div className={"account_button_address"}>
                    <span>{account.displayName}</span>
                  </div>
                </button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWallet;
