import React, {useState} from "react";
import "./Header.css";
import logo from "../../img/logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import allRoutes from "../../config/router/routes/allRoutes.json";
import {useAccount} from "wagmi";
import WalletButton, {ConnectWallet} from "./WalletButton";
import MobileMenu from "./components/MobileMenu";
import Media from "react-media";
import {useAppDispatch} from "../../store/hooks";
import {dispatchResetValues} from "../../store/slices/nftReducer";
import ModalRetry from "../../pages/Bridge/BridgeDesktop/modalConnectWallet/modalRetry/ModalRetry";
import {dispatchVisibleModal} from "../../store/slices/modalSlice";

const Header = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const {address} = useAccount();
    const dispatch = useAppDispatch()
    const location = useLocation()
    return (
        <>
            <div className={`wrap_header ${isOpen && "menu_shadow"}`}>
                <Media query={{maxWidth: 800}}>
                    {(matches) =>
                        matches ? (
                            <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen}/>
                        ) : (
                            <>
                                <div className={"left_side"}>
                                    <img src={logo} alt={"logo"}/>
                                    {(location.pathname === '/' &&  address) && <span onClick={() => dispatch(dispatchVisibleModal({visibleModal: true}))}
                                           className={"navigate_span find_nft"}>Find NFT</span>
                                    }                                    <span
                                        onClick={() => {
                                            if (location.pathname !== "/") dispatch(dispatchResetValues())
                                            navigate(allRoutes.Bridge)
                                        }}
                                        className={"navigate_span"}
                                    >Bridge</span>

                                </div>
                                {!address && <div className={"wrap_beta_text"}>
                                    <span>zkPengz bridge is in a beta mode</span>
                                </div>}
                                <div>
                                    <ConnectWallet/>
                                </div>
                            </>
                        )
                    }
                </Media>
            </div>
        </>

    );
};

export default Header;
