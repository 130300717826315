import React from 'react';
import './App.css';
import Router from "./config/router/Router";

function App() {
  return (
      <>
        <Router/>
      </>
  );
}

export default App;
