import React from "react";

import styles from "./status.module.css";
import NftCard from "../../../Inventory/modalInventoryMobile/components/NftCard";
import {useAppSelector} from "../../../../store/hooks";
import {chainId} from "../../../../chainIds/chainids";
import {getEnv} from "../../../../utils";
import {returnLinkOS} from "../../../functions/returnLinkOs";

const Successful = () => {
    const {scanUrl, lzUrl} = useAppSelector(state => state.chainValues)
    const {tokenId} = useAppSelector(state => state.nftValues)
    const {chainIdTo} = useAppSelector(state => state.choosedChain);

  return (
    <>
        <h1 className={styles.status_title}>congratulations!</h1>
        <h2 className={styles.status_subtitle}>your transfer was succesfull</h2>
        <div className={styles.status_main}>
          <NftCard />
            <div className={styles.wrap_refs}>
                {scanUrl &&
                    <a className={styles.ref_hash_content_done_mobile} href={scanUrl} target={"_blank"}>View on Explorer</a>}
                {lzUrl &&
                    <a className={styles.ref_hash_content_done_mobile} href={lzUrl} target={"_blank"}>Check on LayerZero</a>}
                {returnLinkOS(chainIdTo, tokenId) !== '' && <span className={styles.text_opensea}>
                    OpenSea <a className={styles.ref_opensea} href={returnLinkOS(chainIdTo, tokenId)} target={"_blank"}>link</a>
                </span>}
            </div>

        </div>
    </>
  );
};

export default Successful;
