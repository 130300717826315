import React, {useEffect, useState} from 'react';
import './DropDown.css'
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import logo_zk from '../../img/logo_zk.svg'
import logo_eth from '../../img/logoi_ethereum.svg'
import logo_arb from '../../img/AF_logomark.svg'
import logo_base from '../../img/base-logo.svg'
import logo_optimism from '../../img/optimism_logo.svg'
import logo_nova from '../../img/nova_arb_logo.svg'
import logo_linea from '../../img/linea_logo.svg'
import logo_polygon from '../../img/polygon_logo.svg'
import {
    dispatchChoosedChainValuesFrom,
    dispatchChoosedChainValuesTo
} from "../../store/slices/choosedChain";
import arrow from '../../img/arrow_drop_down.svg'
import {dispatchResetValues} from "../../store/slices/nftReducer";
import {useLocation} from "react-router-dom";
import useGetAllChains from "../../hooks/useGelAllChains";
import {chainNames} from "../../chainIds/chainNames";

interface IDropDown {
    textTitle: string,
}

interface IChain {
    name: string,
    chainId: number,
    remoteChainId: number
}


const DropDown: React.FC<IDropDown> = ({textTitle}) => {
    const [visibleChains, setVisibleChains] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.choosedChain);
    const {allChains} = useAppSelector(state => state.chainValues)
    const {pathname} = useLocation();
    useGetAllChains();
    const imgChoosed = (item: IChain) => {
        switch (item.name) {
            case chainNames.eth : {
                return logo_eth;
            }
            case chainNames.zk : {
                return logo_zk;
            }
            case chainNames.arb : {
                return logo_arb
            }
            case chainNames.base: {
                return logo_base
            }
            case chainNames.opt: {
                return logo_optimism
            }

            case chainNames.arbNova: {
                return logo_nova
            }
            case chainNames.linea: {
                return logo_linea
            }
            case chainNames.zkPol: {
                return logo_polygon
            }
        }
    }

    const revertChains = () => {
        const choosedChain = state.choosedChainFrom;
        const chainId = state.chainIdFrom;
        const remoteChainId = state.remoteChainIdFrom;
        const img = state.imgFrom;
        dispatch(dispatchChoosedChainValuesFrom(
            {choosedChainFrom: state.choosedChainTo,
                chainIdFrom: state.chainIdTo,
                remoteChainIdFrom: state.remoteChainIdTo,
                imgFrom: state.imgTo
            }
        ))
        dispatch(dispatchChoosedChainValuesTo(
            {choosedChainTo: choosedChain,
                chainIdTo: chainId,
                remoteChainIdTo: remoteChainId,
                imgTo: img
            }
        ))
        dispatch(dispatchResetValues())
    }
    const choosedChain = (item: IChain) => {
        if (textTitle === "From") {
            dispatch(dispatchChoosedChainValuesFrom(
                {
                    choosedChainFrom: item.name,
                    imgFrom: imgChoosed(item),
                    remoteChainIdFrom: item.remoteChainId,
                    chainIdFrom: item.chainId
                }))
            if(state.choosedChainFrom !== item.name) dispatch(dispatchResetValues())
        } else {
            dispatch(dispatchChoosedChainValuesTo({
                choosedChainTo: item.name,
                imgTo: imgChoosed(item),
                remoteChainIdTo: item.remoteChainId,
                chainIdTo: item.chainId
            }))
        }
        if(state.choosedChainFrom === item.name && textTitle === "To"){
            revertChains();
        }
        if(state.choosedChainTo === item.name && textTitle === "From"){
            revertChains();
        }
    }

    return (
        <div className={"wrap_dropDawn"}>
            <div onClick={pathname !== "/status-transaction" ?
                () => setVisibleChains(!visibleChains) :
                () => {
                }
            } className={"dropDawn_transfer"}>
                {textTitle && <span className={"text_title"}>{textTitle}</span>}
                <div className={"wrap_values_drop_down"}>
                    <div className={"wrap_logos"}>
                        <img src={textTitle === "To" ? state.imgTo : state.imgFrom}/>
                        <span
                            className={"default_text"}>{(textTitle === "To") ? state.choosedChainTo : state.choosedChainFrom}</span>
                    </div>
                    {pathname !== "/status-transaction" && <img src={arrow}/>}
                </div>

                {visibleChains && <div className={"wrap_chains_values"}>
                    {allChains && allChains.map((item: IChain, index) => (
                        <div key={index} onClick={() => choosedChain(item)} className={"wrap_chains"}>
                            <img src={imgChoosed(item)}/>
                            <span className={"chains_span"}>{item.name}</span>
                        </div>
                    ))}
                </div>}
            </div>
        </div>

    );
};

export default DropDown;