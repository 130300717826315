import React from 'react';
import {Route, Navigate, Routes} from "react-router-dom";
import allRoutes from './routes/allRoutes.json'
import routesMap from "./routes/routesMap";

const Router = () => {
    return (
       <>
           <Routes>
               {
                   Object.values(allRoutes).map((route, index) => (
                       <Route
                           key={index}
                           path={route}
                           element={routesMap[route]}
                       />
                   ))
               }
               <Route
                   path={"*"}
                   element={
                       <Navigate
                           to={allRoutes.Bridge}
                           replace
                       />
                   }
               />
           </Routes>
       </>
    );
};

export default Router;