import React from 'react';
import Header from "../../../components/header/Header";
import ButtonStatus from "../StatusDesktop/buttonStatus/ButtonStatus";
import Footer from "../../../components/footer/Footer";
import Successful from "../StatusMobile/components/Successful";
import ContentDone from "../StatusDesktop/contentDone/ContentDone";
import Media from "react-media";
import '../StatusTransaction.css'
import {Navigate} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
const RetrySuccess = () => {
    const {tokenId} = useAppSelector(state => state.nftValues)
    const {scanUrl} = useAppSelector(state => state.chainValues)
    if (tokenId === 0 && !scanUrl) return <Navigate to={'/'} replace/>
    return (
            <div className={"wrap_main_page"}>
                <Header/>
                <div className={"wrap_status_modal"}>
                    <Media query={{maxWidth: 600}}>
                        {(matches) => (matches ? <Successful/> : <ContentDone/>)}
                    </Media>
                    <ButtonStatus status={"DELIVERED"}/>
                </div>
                <Footer/>
            </div>
    );
};

export default RetrySuccess;