import React from 'react';
import './Button.css';

interface IButton {
    transfer: () => void;
    loading: boolean
}

const Button: React.FC<IButton> = ({transfer, loading}) => {
    return (
        <button
            onClick={loading ? () => transfer() : () => {}}
            className={loading ? "button_confirm_enabled" : "button_confirm_disabled"}>
            <span>Confirm</span>
        </button>
    );
};

export default Button;