import React from 'react';
import './Footer.css'
import discordLogo from '../../img/discord_social.svg'
import twitterLogo from '../../img/twitter-social.svg'
const Footer = () => {
    return (
        <div className={"wrap_footer"}>
            <div className={"wrap_social"}>
                <a href={'https://twitter.com/zkPengz'} target={"_blank"}>
                    <img src={twitterLogo}/>
                </a>
                <a href={'https://discord.gg/DdqX4G2c4U'} target={"_blank"}>
                    <img src={discordLogo}/>
                </a>
            </div>
            <span className={"text_footer"}>ZKPengz. 2024</span>
        </div>
    );
};

export default Footer;
