import React from "react";

import peng_nft from "../../../../img/peng_nft.png";
import styles from "../choose_nft.module.css";
import { useLocation } from "react-router-dom";
import {useAppSelector} from "../../../../store/hooks";

const NftCard = () => {
  const { pathname } = useLocation();
  const state = useAppSelector(state => state.nftValues)

  return (
    <div
      className={
        pathname === "/transfer-info"
          ? styles.nft_card_wrapper_transaction
          : pathname === "/status-transaction"
          ? styles.nft_card_wrapper_status
          : styles.nft_card_wrapper
      }
    >
      <img className={styles.nft_card_img} src={state.image} alt="nft" />
      <div className={styles.nft_card_inner_wrapper}>
        <h2 className={styles.nft_card_title}>Zk Pengz</h2>
        <p className={styles.nft_card_number}>#{state.tokenId}</p>
      </div>
    </div>
  );
};

export default NftCard;
