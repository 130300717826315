import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    fee: '',
}

export const getFeeSliceReducer = createSlice({
    name: 'getFee',
    initialState,
    reducers: {
        dispatchGetFee: (state, action) => {
            const {fee} = action.payload
            function shortenAddress() {
                const prefixLength = 6; // количество символов в начале адреса
                const prefix = fee.slice(0, prefixLength);
                return `${prefix}`;
            }
            state.fee = shortenAddress()
        },


    },
})

export const { dispatchGetFee } = getFeeSliceReducer.actions

export default getFeeSliceReducer.reducer