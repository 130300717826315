import React from "react";
import ChooseChain from "../../../../components/chooseChain/ChooseChain";
import {ReactComponent as PengIcon} from "../../../../img/peng_mobile.svg";
import styles from "./ContentInProgressMobile.module.css";
import {useAppSelector} from "../../../../store/hooks";
import DropDown from "../../../../components/dropDown/DropDown";

const ContentInProgressMobile = () => {
    const {scanUrl, lzUrl} = useAppSelector(state => state.chainValues)
    return (
        <>
            <h1 className={styles.accepted_title}>your transfer is accepted</h1>
            <div className={styles.accepted_main}>
                <div>
                    <DropDown textTitle={"From"}/>
                </div>
                <div>
                    <PengIcon />
                </div>
                <div>
                    <PengIcon />
                </div>
                <div>
                    <DropDown textTitle={"To"}/>
                </div>
            </div>
            {scanUrl && <a className={styles.ref_hash} href={scanUrl} target={"_blank"}>View on Explorer</a>}
            {lzUrl && <a className={styles.ref_hash_next} href={lzUrl} target={"_blank"}>Check on LayerZero</a>}
        </>
    );
};

export default ContentInProgressMobile;
