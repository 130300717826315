import React, {useEffect} from 'react';
import './ContentDone.css'
import {useAppSelector} from "../../../../store/hooks";
import done from '../../../../img/done_check.png'
import {chainId} from "../../../../chainIds/chainids";
import {getEnv} from "../../../../utils";
import {returnLinkOS} from "../../../functions/returnLinkOs";


const ContentDone = () => {
    const state = useAppSelector(state => state.nftValues);
    const {scanUrl, lzUrl} = useAppSelector(state => state.chainValues);
    const {tokenId} = useAppSelector(state => state.nftValues)
    const {chainIdTo} = useAppSelector(state => state.choosedChain);

    return (
        <>
            <div className={"wrap_title"}>
                <span className={"title"}>SUCCESS!</span>
            </div>
            <div className={"wrap_modal_status_done"}>

                <div className={"wrap_title_status_done"}>
                    <span className="title_status">Congratulations!</span>
                    <span className={"text_under_title_status"}>Transfer was successful</span>
                </div>
                <div className={"wrap_chains_status_done"}>
                    <div className={"wrap_nft_content"}>
                        <div className={"nft_content"}>
                            <img className={"img_nft"} src={state.image}/>
                            <div className={"wrap_status_values"}>
                                <span>Zk Pengz</span>
                                <span>#{state.tokenId}</span>
                            </div>
                        </div>
                        <img src={done}/>
                    </div>
                </div>
                {scanUrl &&
                    <a className={"ref_hash_content_done"} href={scanUrl} target={"_blank"}>View on Explorer</a>}
                {lzUrl &&
                    <a className={"ref_hash_content_done"} href={lzUrl} target={"_blank"}>Check on LayerZero</a>}
                {returnLinkOS(chainIdTo, tokenId) !== '' && <span className={"text_opensea"}>
                    OpenSea <a className={"ref_opensea"} href={returnLinkOS(chainIdTo, tokenId)} target={"_blank"}>link</a>
                </span>}
            </div>

        </>

    );
};

export default ContentDone;
