import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { chains, wagmiClient } from "./services/config";
import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import {Provider} from "react-redux";
import {store} from "./store";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider theme={darkTheme()} chains={chains}>
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </RainbowKitProvider>
            </WagmiConfig>
        </BrowserRouter>
    // </React.StrictMode>
);
