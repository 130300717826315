import React, {useEffect, useState} from 'react';
import './StatusTransaction.css'
import Header from "../../components/header/Header";
import ButtonStatus from "./StatusDesktop/buttonStatus/ButtonStatus";
import {useAppSelector} from '../../store/hooks';
import Footer from "../../components/footer/Footer";
import {Navigate} from "react-router-dom";
import Media from "react-media";
import Successful from "./StatusMobile/components/Successful";
import ContentDone from "./StatusDesktop/contentDone/ContentDone";
import ContentInProgressMobile from "./StatusMobile/contentInProgressMobile/ContentInProgressMobile";
import ContentInProgress from "./StatusDesktop/contentInProgress/ContentInProgress";
import useStatus from "../../hooks/useStatus";
import ContentFailed from "./StatusDesktop/contentFailed/ContentFailed";
import Unsuccessful from "./StatusMobile/components/Unsuccessful";
import {dispatchResetValues} from "../../store/slices/nftReducer";
import allRoutes from "../../config/router/routes/allRoutes.json";

const StatusTransaction = () => {
    const {tokenId} = useAppSelector(state => state.nftValues)
    const {scanUrl} = useAppSelector(state => state.chainValues)
    const status = useStatus();
    if (tokenId === 0 && !scanUrl) return <Navigate to={'/'} replace/>;

    const returnContentStatus = {
        "INFLIGHT": (
            <Media query={{maxWidth: 600}}>
                {(matches) => (matches ? <ContentInProgressMobile/> : <ContentInProgress/>)}
            </Media>
        ),
        "DELIVERED": (
            <Media query={{maxWidth: 600}}>
                {(matches) => (matches ? <Successful/> : <ContentDone/>)}
            </Media>
        ),
        "BLOCKED": (
            <Media query={{maxWidth: 600}}>
                {(matches) => (matches ? <Unsuccessful/> : <ContentFailed/>)}
            </Media>
        ),
        "FAILED": (
            <Media query={{maxWidth: 600}}>
                {(matches) => (matches ? <Unsuccessful/> : <ContentFailed/>)}
            </Media>
        ),
        "PAYLOAD_STORED": (
            <Media query={{maxWidth: 600}}>
                {(matches) => (matches ? <Unsuccessful/> : <ContentFailed/>)}
            </Media>
        )
    }

    return (
        <div className={"wrap_main_page"}>
            <Header/>
            <div className={"wrap_status_modal"}>
                {returnContentStatus[status]}
                <ButtonStatus status={status}/>
            </div>
            <Footer/>
        </div>
    );
}




export default StatusTransaction;