import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    nftsArray: [],
    nftsFilter: []
}

export const nftArraySliceReducer = createSlice({
    name: 'nftArray',
    initialState,
    reducers: {
        dispatchNftArray: (state, action) => {
            const {nftsArray} = action.payload
            state.nftsArray = nftsArray;
        },

        dispatchFilterNftArray: (state, action) => {
            const {nftsFilter} = action.payload;
            state.nftsFilter = nftsFilter;
        }
    },
})

export const { dispatchNftArray, dispatchFilterNftArray } = nftArraySliceReducer.actions

export default nftArraySliceReducer.reducer