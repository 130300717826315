import React from 'react';
import './Nft.css'
import {INft} from "../ModalInventory";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {dispatchNftValues} from "../../../../store/slices/nftReducer";
import {useNavigate} from "react-router-dom";
import {chainId} from "../../../../chainIds/chainids";
const Nft: React.FC<INft> = ({name, image, chain, tokenId}) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.nftValues)
    const navigate = useNavigate()
    const setState = () => {
        dispatch(dispatchNftValues({tokenId: tokenId, image: image, chainNft: chain, name:name}));
        navigate(-1)
    }

    return (
        <div onClick={() =>setState()}
             className={"wrap_nft_inventory"}>
            <img src={image}/>
            <div className={"wrap_nft_value_inventory"}>
                <span>Zk Pengz</span>
                <span>#{tokenId}</span>
            </div>

        </div>
    );
};

export default Nft;