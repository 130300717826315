import {useEffect} from "react";
import axios from "axios";
import {dispatchGetFee} from "../store/slices/getFeeSlice";
import {ethers} from "ethers";
import {useAccount} from "wagmi";
import {getEnv} from "../utils";
import {useAppDispatch, useAppSelector} from "../store/hooks";

const useGetFee = () => {
    const { address } = useAccount();
    const { tokenId, chainNft } = useAppSelector((state) => state.nftValues);
    const serverUrl = getEnv(process.env.REACT_APP_SERVER_URL, "REACT_APP_SERVER_URL");
    const dispatch = useAppDispatch();
    const {remoteChainIdFrom, remoteChainIdTo} = useAppSelector(state => state.choosedChain)
    const getFee = async () => {
        await axios.get(`${serverUrl}/fee?toChainId=${remoteChainIdTo}&userAddress=${address}&tokenId=${tokenId}&fromChainId=${remoteChainIdFrom}`)
            .then((res) =>
                dispatch(dispatchGetFee({ fee: ethers.utils.formatUnits(res.data.fee) }))
            );
    };
    useEffect(() => {
        getFee();
    }, [address, tokenId]);
}

export default useGetFee