import React from 'react';
import './ConnectButton.css'
import Media from "react-media";
import { ConnectButton } from '@rainbow-me/rainbowkit';

const ConnectButtonDisc = () => {
    return (
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                return (
                    <div style={{width: '100%'}}
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',

                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                        <button onClick={openConnectModal} className={"wrap_button_disc"}>
                                            <span

                                                className={"connect_button_disc"}
                                            >
											Connect wallet
										</span>
                                        </button>
                                );
                            }
                            if (chain.unsupported) {
                                return (
                                        <button onClick={openChainModal} className={"wrap_button"}>
                                            <span
                                                className={"connect_button"}
                                            >
											Wrong network
										</span>
                                        </button>
                                );
                            }

                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};

export default ConnectButtonDisc;