import React from 'react';
import {useAccount} from "wagmi";
import ChooseNft from "../../Inventory/modalInventoryMobile/ChooseNft";
import Homepage from "./Homepage/Homepage";
import ModalConnectWallet from "../BridgeDesktop/modalConnectWallet/ModalConnectWallet";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";

const BridgeMobile = () => {
    const {isConnected} = useAccount();
    return (
        <div className={"wrap_main_page"}>
            <Header/>
            <div className={"wrap_content"}>
                {isConnected ? <ModalConnectWallet/> : <Homepage/>}
            </div>
            <Footer/>
        </div>
    );
};

export default BridgeMobile;