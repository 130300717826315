import { INft } from "../pages/Inventory/modalInventory/ModalInventory";
import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import {dispatchFilterNftArray, dispatchNftArray} from "../store/slices/nftArraySlice";
import {useAppSelector} from "../store/hooks";
import axios from "axios";
import {useAccount} from "wagmi";
import {getEnv} from "../utils";
import {chainId} from "../chainIds/chainids";
import {dispatchResetValues} from "../store/slices/nftReducer";

const useFilterNft = (): INft[] => {
    const {nftsArray} = useAppSelector(state => state.nftArray)
    const dispatch = useDispatch();
    const { chainIdFrom } = useAppSelector(state => state.choosedChain);
    const {address} = useAccount();
    const serverUrl = getEnv(process.env.REACT_APP_SERVER_URL, "REACT_APP_SERVER_URL");

    const getNftsUser = async () => {
        await axios.get(`${serverUrl}/nfts?userAddress=${address}`)
            .then(res => dispatch(dispatchNftArray({nftsArray: res.data})))
    }

    const filteredNfts: INft[] = useMemo(() => {
        dispatch(dispatchResetValues())
        switch (chainIdFrom) {
            case chainId.zk: {
                return nftsArray?.filter((item: INft) => item.chain === 'zk') ?? [];
            }
            case chainId.eth: {
                return nftsArray?.filter((item: INft) => item.chain === 'eth') ?? [];
            }
            case chainId.arb: {
                return nftsArray?.filter((item: INft) => item.chain === 'arb') ?? [];
            }
            case chainId.base: {
                return nftsArray?.filter((item: INft) => item.chain === 'base') ?? [];
            }
            case chainId.zkPol: {
                return nftsArray?.filter((item: INft) => item.chain === 'zkPolygon') ?? [];
            }
            case chainId.opt: {
                return nftsArray?.filter((item: INft) => item.chain === 'opt') ?? [];
            }
            case chainId.linea: {
                return nftsArray?.filter((item: INft) => item.chain === 'linea') ?? [];
            }
            case chainId.nova: {
                return nftsArray?.filter((item: INft) => item.chain === 'arbNova') ?? [];
            }
            default : {
                return []
            }
        }
    }, [chainIdFrom, nftsArray, address]);

    useEffect(() => {
        async function fetchData() {
            await getNftsUser();
            dispatch(dispatchFilterNftArray({ nftsFilter: filteredNfts }));
            dispatch(dispatchResetValues())
        }
        fetchData();
    }, [address]);

    return filteredNfts;
};

export default useFilterNft;