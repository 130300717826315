import {getEnv} from "../../utils";
import {chainId} from "../../chainIds/chainids";

const contractEth = getEnv(process.env.REACT_APP_OMNI_CONTRACT_ADDRESS, 'REACT_APP_OMNI_CONTRACT_ADDRESS');
const contractArb = getEnv(process.env.REACT_APP_ARB_CONTRACT_ADDRESS, "REACT_APP_ARB_CONTRACT_ADDRESS");
const contractOpt = getEnv(process.env.REACT_APP_OPTIMISM_CONTRACT_ADDRESS, 'REACT_APP_OPTIMISM_CONTRACT_ADDRESS')
export const returnLinkOS = (chainIdTo : number, tokenId: number) => {
    switch (chainIdTo) {
        case chainId.eth : {
            return `https://opensea.io/assets/ethereum/${contractEth}/${tokenId}`
        }
        case chainId.arb: {
            return `https://opensea.io/assets/arbitrum/${contractArb}/${tokenId}`
        }
        case chainId.opt: {
            return `https://opensea.io/assets/optimism/${contractOpt}/${tokenId}`
        }
        default: {
            return ''
        }
    }
}