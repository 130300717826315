import React, { useEffect, useState } from "react";
import "./ModalTransferInfo.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { approve, isApproved, sendToken } from "../../../services/contract";
import { getEnv } from "../../../utils";
import axios from "axios";
import { dispatchGetFee } from "../../../store/slices/getFeeSlice";
import { ethers } from "ethers";
import Button from "./button/Button";
import { useNavigate } from "react-router-dom";
import allRoutes from "../../../config/router/routes/allRoutes.json";
import layer_zero from "../../../img/LayerZero_Logo_Icon.svg";
import {dispatchHash, dispatchScanUrl, dispatchLzUrl} from "../../../store/slices/chainSlice";
import useGetFee from "../../../hooks/useGetFee";
import {chainId} from "../../../chainIds/chainids";
import {chains} from "../../../services/config";

const ModalTransferInfo = () => {
  const { tokenId, image, name, chainNft } = useAppSelector((state) => state.nftValues);
  const { fee } = useAppSelector((state) => state.getFee);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const dispatch = useAppDispatch();
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(true);
  const navigate = useNavigate();
  const {remoteChainIdFrom, remoteChainIdTo, chainIdFrom} = useAppSelector(state => state.choosedChain)
  const scanUrlEth = getEnv(process.env.REACT_APP_SCAN_URL_ETH, "REACT_APP_SCAN_URL_ETH")
  const scanUrlZk = getEnv(process.env.REACT_APP_SCAN_URL_ZK, "REACT_APP_SCAN_URL_ZK")
  const scanUrlArb = getEnv(process.env.REACT_APP_SCAN_URL_ARB, "REACT_APP_SCAN_URL_ARB")
  const scanUrlOpt = getEnv(process.env.REACT_APP_SCAN_URL_OPT, 'REACT_APP_SCAN_URL_OPT');
  const scanUrlBase = getEnv(process.env.REACT_APP_SCAN_URL_BASE, 'REACT_APP_SCAN_URL_BASE')
  const scanUrlNoVa = getEnv(process.env.REACT_APP_SCAN_URL_NOVA, 'REACT_APP_SCAN_URL_NOVA');
  const scanUrlLinea = getEnv(process.env.REACT_APP_SCAN_URL_LINEA, 'REACT_APP_SCAN_URL_LINEA');
  const scanUrlZkPolygon = getEnv(process.env.REACT_APP_SCAN_URL_ZKPOLYGON, 'REACT_APP_SCAN_URL_ZKPOLYGON')
  const scanUrlLz = getEnv(process.env.REACT_APP_SCAN_URL_LZ, 'REACT_APP_SCAN_URL_LZ')
  // 5  eto goeli 280 zk
  function shortenAddress(address: string | undefined) {
    const prefixLength = 26; // количество символов в начале адреса
    const suffixLength = 3; // количество символов в конце адреса
    const ellipsis = "..."; // многоточие
    const prefix = address?.slice(0, prefixLength);
    const suffix = address?.slice(-suffixLength);
    return `${prefix}${ellipsis}${suffix}`;
  }

  const scanUrl = () => {
    switch (chainIdFrom) {
      case chainId.eth : {
        return scanUrlEth
      }
      case chainId.zk:{
        return scanUrlZk
      }
      case chainId.arb: {
        return scanUrlArb
      }
      case chainId.linea: {
        return scanUrlLinea
      }
      case chainId.opt: {
        return scanUrlOpt
      }
      case chainId.nova: {
        return scanUrlNoVa
      }
      case chainId.base: {
        return scanUrlBase
      }
      case chainId.zkPol: {
        return scanUrlZkPolygon
      }
    }
  }

  const transferFrom = async (tokenId: number) => {
    setLoadingConfirm(false);
    if (chain?.id !== chainIdFrom) await switchNetworkAsync?.(chainIdFrom);

    const approved = await isApproved(address as string);
    if (!approved.isApproved) await approve();

    const hash = await sendToken(remoteChainIdTo, remoteChainIdFrom, address as string, tokenId);
    dispatch(dispatchHash({hash: hash}))
    dispatch(
        dispatchScanUrl({
          scanUrl: `${scanUrl()}tx/${hash}`
        })
    );
    dispatch(
        dispatchLzUrl({
          lzUrl: `${scanUrlLz}tx/${hash}`
        })
    );
    setLoadingConfirm(true);
    navigate(allRoutes.Status);
  };



  const transfer = async () => {
    try {
      await transferFrom(tokenId);
    } catch (e) {
      setLoadingConfirm(true);
    }
  };
  useGetFee();


  return (
    <div className={"wrap_transfer_info"}>
      <span onClick={() => navigate(-1)} className={"close_button"}>
        X
      </span>
      <span className={"title_transfer_info"}>Transfer Info</span>
      <div className={"wrap_nft_value"}>
        <img src={image} alt={"nft_image"} />
        <span>{name}</span>
      </div>
      <div className={"text_process"}>
        <span>The process will take around 2 min</span>
      </div>
      <div className={"active_wallet"}>
        <span className={"text_active"}>Active wallet:</span>
        <span className={"text_address"}>{shortenAddress(address)}</span>
      </div>
      {fee && <span className="text_fee">L0 Fee : {fee} ETH</span>}
      <Button transfer={transfer} loading={loadingConfirm} />
      <div className={"wrap_powered_by"}>
        <img src={layer_zero} />
        <span className={"powered_by"}>Powered by Layerzero</span>
      </div>
    </div>
  );
};

export default ModalTransferInfo;
