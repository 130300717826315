import React from "react";
import styles from "./choose_nft.module.css";
import ChooseChain from "../../../components/chooseChain/ChooseChain";
import useFilterNft from "../../../hooks/useFilterNft";
import Nft from "../modalInventory/NftComponentinventory/Nft";
import { INft } from "../modalInventory/ModalInventory";
import { useNavigate } from "react-router-dom";
import allRoutes from "../../../config/router/routes/allRoutes.json";
import { useAppDispatch } from "../../../store/hooks";
import DropDown from "../../../components/dropDown/DropDown";

const ChooseNft = () => {
  const filteredNfts = useFilterNft();


  return (
    <div className={styles.container}>
      <h1 className={styles.choose_title}>Choose nft</h1>
      <div className={styles.choose_wrapper}>
        <DropDown textTitle={"From"} />
        <DropDown textTitle={"To"} />
      </div>
        {filteredNfts.length !== 0 ? <div className={styles.nft_wrapper}>
            {filteredNfts?.map((item: INft) => (
                <div key={item.tokenId}>
                    <Nft
                        name={item.name}
                        chain={item.chain}
                        image={item.image}
                        tokenId={item.tokenId}
                    />
                </div>
            ))}
        </div> : <div className={styles.wrap_nonft}>
            <span className={styles.text_nonft}>No Nfts in this Chain</span>
        </div>}
    </div>
  );
};

export default ChooseNft;
