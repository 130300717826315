import {configureStore} from "@reduxjs/toolkit";
import nftSliceReducer from "./slices/nftReducer";
import chainSliceReducer from "./slices/chainSlice";
import nftArraySliceReducer from "./slices/nftArraySlice";
import choosedChainSliceReducer from "./slices/choosedChain";
import getFeeSliceReducer from "./slices/getFeeSlice";
import modalSliceReducer from "./slices/modalSlice";


export const store = configureStore({
    reducer:{
        nftValues: nftSliceReducer,
        chainValues: chainSliceReducer,
        nftArray: nftArraySliceReducer,
        choosedChain: choosedChainSliceReducer,
        getFee: getFeeSliceReducer,
        modal: modalSliceReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch