import {chainId} from "../chainIds/chainids";
import {dispatchChoosedChainValuesFrom, dispatchChoosedChainValuesTo} from "../store/slices/choosedChain";
import axios from "axios";
import {dispatchAllChains} from "../store/slices/chainSlice";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getEnv} from "../utils";
interface IChain {
    name: string,
    chainId: number,
    remoteChainId: number
}

const useGetAllChains = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.choosedChain);
    const {allChains} = useAppSelector(state => state.chainValues)
    const serverUrl = getEnv(process.env.REACT_APP_SERVER_URL, "REACT_APP_SERVER_URL");
    const getDefaultChains = (data : IChain[]) => {
        data.map((item : IChain) => {
            if(item.chainId === chainId.zk) {
                dispatch(dispatchChoosedChainValuesFrom({
                    choosedChainFrom: item.name,
                    imgFrom: state.imgFrom,
                    remoteChainIdFrom: item.remoteChainId,
                    chainIdFrom: item.chainId
                }))
            }
            if(item.chainId === chainId.eth) {
                dispatch(dispatchChoosedChainValuesTo({
                    choosedChainTo: item.name,
                    imgTo: state.imgTo,
                    remoteChainIdTo: item.remoteChainId,
                    chainIdTo: item.chainId
                }))
            }
        })

    }
    const getAllChains = async () => {
        await axios.get(`${serverUrl}/chains`)
            .then(res => {
                if(allChains.length === 0){
                    getDefaultChains(res.data);
                }
                dispatch(dispatchAllChains({allChains: res.data}));
            })
    }

    useEffect(()=> {
        if(allChains.length === 0){
            getAllChains();
        }
    }, [])
}

export default useGetAllChains;
