import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    visibleModal: false,
}

export const modalSliceReducer = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        dispatchVisibleModal: (state, action) => {
            const {visibleModal} = action.payload
            state.visibleModal = visibleModal
        },


    },
})

export const { dispatchVisibleModal } = modalSliceReducer.actions

export default modalSliceReducer.reducer