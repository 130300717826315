import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {Chain, configureChains, createClient, goerli, mainnet} from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { getEnv } from "../utils";
import { metaMaskWallet, trustWallet } from '@rainbow-me/rainbowkit/wallets';
import {chainId} from "../chainIds/chainids";
import logo_zk from '../img/logo_zk.svg'
import logo_base from '../img/base-logo.svg'
import logo_nova from '../img/nova_arb_logo.svg'
import logo_linea from '../img/linea_logo.svg'
import logo_polygon from '../img/polygon_logo.svg'

const alchemyApiKey = getEnv(process.env.REACT_APP_ALCHEMY_KEY, 'REACT_APP_ALCHEMY_KEY');

const zkNodeUrl = getEnv(process.env.REACT_APP_ZK_SYNC_NODE_URL, 'REACT_APP_ZK_SYNC_NODE_URL');
const arbNodeUrl = getEnv(process.env.REACT_APP_ARB_NODE_URL, 'REACT_APP_ARB_NODE_URL');
const optNodeUrl = getEnv(process.env.REACT_APP_OPT_NODE_URL, 'REACT_APP_OPT_NODE_URL');
const baseNodeUrl = getEnv(process.env.REACT_APP_BASE_NODE_URL, 'REACT_APP_BASE_NODE_URL');
const novaNodeUrl = getEnv(process.env.REACT_APP_NOVA_NODE_URL, 'REACT_APP_NOVA_NODE_URL');
const lineaNodeUrl = getEnv(process.env.REACT_APP_LINEA_NODE_URL, 'REACT_APP_LINEA_NODE_URL');
const zkPolygonNodeUrl = getEnv(process.env.REACT_APP_ZKPOLYGON_NODE_URL, 'REACT_APP_ZKPOLYGON_NODE_URL');

const nameZk = getEnv(process.env.REACT_APP_NAME_ZK, "REACT_APP_NAME_ZK");
const nameArb = getEnv(process.env.REACT_APP_NAME_ARB, 'REACT_APP_NAME_ARB');

const scanUrlZk = getEnv(process.env.REACT_APP_SCAN_URL_ZK, 'REACT_APP_SCAN_URL_ZK');
const scnaUrlArb = getEnv(process.env.REACT_APP_SCAN_URL_ARB, 'REACT_APP_SCAN_URL_ARB');
const scanUrlOpt = getEnv(process.env.REACT_APP_SCAN_URL_OPT, 'REACT_APP_SCAN_URL_OPT');
const scanUrlBase = getEnv(process.env.REACT_APP_SCAN_URL_BASE, 'REACT_APP_SCAN_URL_BASE')
const scanUrlNoVa = getEnv(process.env.REACT_APP_SCAN_URL_NOVA, 'REACT_APP_SCAN_URL_NOVA');
const scanUrlLinea = getEnv(process.env.REACT_APP_SCAN_URL_LINEA, 'REACT_APP_SCAN_URL_LINEA');
const scanUrlZkPolygon = getEnv(process.env.REACT_APP_SCAN_URL_ZKPOLYGON, 'REACT_APP_SCAN_URL_ZKPOLYGON')

const zkSyncChain: Chain = {
  id: chainId.zk,
  name: nameZk,
  network: 'zkSync',
  iconUrl: logo_zk,
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [zkNodeUrl],
    },
    default: {
      http: [zkNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'zkSync', url: scanUrlZk },
    etherscan: { name: 'zkSync', url: scanUrlZk },
  },
}

const arbitrumChain: Chain = {
  id: chainId.arb,
  name: nameArb,
  network: 'Arbitrum',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [arbNodeUrl],
    },
    default: {
      http: [arbNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Arbitrum', url: scnaUrlArb },
    etherscan: { name: 'Arbitrum', url: scnaUrlArb },
  },
}

const optimismChain: Chain = {
  id: chainId.opt,
  name: "Optimism",
  network: 'Optimism',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [optNodeUrl],
    },
    default: {
      http: [optNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Optimism', url: scanUrlOpt },
    etherscan: { name: 'Optimism', url: scanUrlOpt },
  },
}

const baseChain: Chain = {
  id: chainId.base,
  iconUrl: logo_base,
  name: "Base",
  network: 'Base',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [baseNodeUrl],
    },
    default: {
      http: [baseNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Base', url: scanUrlBase },
    etherscan: { name: 'Base', url: scanUrlBase },
  },
}

const novaChain: Chain = {
  id: chainId.nova,
  iconUrl: logo_nova,
  name: "Nova",
  network: 'Nova',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [novaNodeUrl],
    },
    default: {
      http: [novaNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Nova', url: scanUrlNoVa },
    etherscan: { name: 'Nova', url: scanUrlNoVa },
  },
}

const lineaChain: Chain = {
  id: chainId.linea,
  iconUrl: logo_linea,
  name: "Linea Mainnet",
  network: 'Linea Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [lineaNodeUrl],
    },
    default: {
      http: [lineaNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Linea', url: scanUrlLinea },
    etherscan: { name: 'Linea', url: scanUrlLinea },
  },
}

const zkPolygonChain: Chain = {
  id: chainId.zkPol,
  iconUrl: logo_polygon,
  name: "Polygon zkEVM Mainnet",
  network: 'Polygon zkEVM Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: [zkPolygonNodeUrl],
    },
    default: {
      http: [zkPolygonNodeUrl],
    },
  },
  blockExplorers: {
    default: { name: 'Polygon zkEVM Mainnet', url: scanUrlZkPolygon },
    etherscan: { name: 'Polygon zkEVM Mainnet', url: scanUrlZkPolygon },
  },
}


export const { chains, provider } = configureChains(
    //goerli
  [zkSyncChain,optimismChain, goerli, arbitrumChain, baseChain, novaChain, lineaChain, zkPolygonChain],
  [
    alchemyProvider({ apiKey: alchemyApiKey }),
    publicProvider()
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ chains }),
      // trustWallet({ chains })
    ]
  }
])

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})