import React from "react";
import WalletButton from "../../../../components/header/WalletButton";
import { ReactComponent as PengIcon } from "../../../../img/peng_mobile.svg";

import styles from "./homepage.module.css";
import { useAccount } from "wagmi";
import ChooseChain from "../../../../components/chooseChain/ChooseChain";
import DropDown from "../../../../components/dropDown/DropDown";

const Homepage = () => {
  const { address } = useAccount();

  return (
    <div className={styles.container}>
        <h1 className={styles.homepage_title}>
          Transfer your NFTs between blockchains
        </h1>
        <div className={styles.homepage_main}>
          <div>
            <DropDown textTitle={"From"} />
          </div>
          <div>
            <PengIcon />
          </div>
          <div>
            <PengIcon />
          </div>
          <div>
            <DropDown textTitle={"To"}/>
          </div>
        </div>
        <WalletButton />
    </div>
  );
};

export default Homepage;
