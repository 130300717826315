import React from 'react';
import './ModalDiscWallet.css'
import pengImg from '../../../../img/img_peng_start.png'
import arrow from '../../../../img/arrow.svg'
import ChooseChain from "../../../../components/chooseChain/ChooseChain";
import ConnectButtonDisc from "./components/connectButton/ConnectButton";
import WalletButton from "../../../../components/header/WalletButton";
import DropDown from "../../../../components/dropDown/DropDown";
const ModalDiscWallet = () => {
    return (
        <div className={"wrap_modal_div"}>
            <div className={"wrap_title"}>
                        <span className={"title"}>
                            Transfer your NFTs between blockchains
                        </span>
            </div>
            <div className={"wrap_modal_disc"}>
                <div className={"wrap_content_modal"}>
                    <img src={pengImg} alt={"img_peng"}/>
                    <div className={"transfer_disc"}>
                        <DropDown
                            textTitle={"From"}
                        />
                        <img className={"arrow_disc"} src={arrow} alt={"arrow"}/>
                        <DropDown textTitle={"To"}/>
                    </div>
                </div>
                <div className={"wrap_under_text"}>
                    <span>You need connect wallet to continue</span>
                </div>
            </div>
            <ConnectButtonDisc/>
        </div>

    );
};

export default ModalDiscWallet;