import React, {useState} from 'react';
import './ButtonStatus.css'
import allRoutes from '../../../../config/router/routes/allRoutes.json'
import {useNavigate} from 'react-router-dom';
import {dispatchResetValues} from "../../../../store/slices/nftReducer";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import Media from "react-media";
import ContentInProgressMobile from "../../StatusMobile/contentInProgressMobile/ContentInProgressMobile";
import ContentInProgress from "../contentInProgress/ContentInProgress";
import Successful from "../../StatusMobile/components/Successful";
import ContentDone from "../contentDone/ContentDone";
import Unsuccessful from "../../StatusMobile/components/Unsuccessful";
import ContentFailed from "../contentFailed/ContentFailed";
import {approve, isApproved, listenReceiveEvent, retryTransaction, sendToken} from "../../../../services/contract";
import {useAccount, useNetwork, useSwitchNetwork} from "wagmi";
import {dispatchHash, dispatchScanUrl, dispatchLzUrl} from "../../../../store/slices/chainSlice";
import {chainId} from "../../../../chainIds/chainids";
import {getEnv} from "../../../../utils";
import useStatus, {StatusType} from "../../../../hooks/useStatus";
import {ethers} from "ethers";
import Web3 from "web3";

interface IButtonStatus {
    status: StatusType
}

const ButtonStatus: React.FC<IButtonStatus> = ({status} ) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {hash} = useAppSelector(state => state.chainValues)
    const {chainIdTo, chainIdFrom, remoteChainIdTo, remoteChainIdFrom} = useAppSelector(state => state.choosedChain)
    const {chain} = useNetwork();
    const {switchNetworkAsync} = useSwitchNetwork();
    const [text, setText] = useState<string>("Try again")
    const {address} = useAccount()
    const {tokenId} = useAppSelector((state) => state.nftValues);
    const scanUrlEth = getEnv(process.env.REACT_APP_SCAN_URL_ETH, "REACT_APP_SCAN_URL_ETH")
    const scanUrlZk = getEnv(process.env.REACT_APP_SCAN_URL_ZK, "REACT_APP_SCAN_URL_ZK")
    const scanUrlArb = getEnv(process.env.REACT_APP_SCAN_URL_ARB, "REACT_APP_SCAN_URL_ARB")
    const scanUrlLz = getEnv(process.env.REACT_APP_SCAN_URL_LZ, 'REACT_APP_SCAN_URL_LZ')
    const retry = async () => {
        if (chain?.id !== chainIdTo) await switchNetworkAsync?.(chainIdTo)
        try {
            setText("Pending…")
            const transaction = await retryTransaction(hash, 0);
            const res = await transaction.wait();
            if (res && res.status == 1) {
                navigate(allRoutes.Success)
            } else if(res &&  res.status === 0){
                throw new Error("failed")
            }
        } catch (e) {
            setText("try again")
        }
    }
    const scanUrl = () => {
        switch (chainIdFrom) {
            case chainId.eth : {
                return scanUrlEth
            }
            case chainId.zk: {
                return scanUrlZk
            }
            case chainId.arb: {
                return scanUrlArb
            }
        }
    }
    const retryFailed = async () => {
        setText("Pending…")
        try{
            if (chain?.id !== chainIdFrom) await switchNetworkAsync?.(chainIdFrom);
            try{
                const approved = await isApproved(address as string);
                if (!approved.isApproved) await approve();
                const hash = await sendToken(remoteChainIdTo, remoteChainIdFrom, address as string, tokenId);
                dispatch(dispatchHash({hash: hash}))
                dispatch(
                    dispatchScanUrl({
                        scanUrl: `${scanUrl()}tx/${hash}`
                    })
                );
                dispatch(
                    dispatchLzUrl({
                        lzUrl: `${scanUrlLz}tx/${hash}`
                    })
                );
                navigate(allRoutes.Status)
            } catch (e){
                setText("Try again")
            }
        } catch (e) {
            setText("Try again")
        }
    }

    const returnButton = {
        "INFLIGHT": (
            <button className={"button_status_progress"}>
                <span>In progress</span>
            </button>
        ),
        "DELIVERED": (
            <button onClick={() => {
                dispatch(dispatchResetValues())
                navigate(allRoutes.Bridge)
            }} className={"button_status_done"}>
                <span>Done</span>
            </button>
            ),
        "BLOCKED": (
            <button onClick={text === "Try again" ? () => retryFailed() : () => {
            }}
                    className={text === "Try again" ? "button_status_failed" : "button_status_progress"}>
                <span>{text}</span>
            </button>
        ),
        "FAILED": (
            <button onClick={text === "Try again" ? () => retryFailed() : () => {
            }}
                    className={text === "Try again" ? "button_status_failed" : "button_status_progress"}>
                <span>{text}</span>
            </button>
        ),
        "PAYLOAD_STORED": (
            <button onClick={() => retry()} className={"button_status_failed"}>
                <span>{text}</span>
            </button>
        )
    }

    return (
        <>
            {returnButton[status]}
        </>
    )
}




export default ButtonStatus;
